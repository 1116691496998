<div class="container text-center mt-5" style="display:flex;justify-content: center;"></div>
  <div class="card mx-auto w-50 nav-cst-shadow2" style="position: sticky;top: 520px;left:50%;transform: translateX(-50%);">
    <div class="card-body">
      <form id="WhistleblowerLoginForm" autocomplete="off" novalidate>
        <div style="text-align: center;">
          <label id="WhistleblowerLoginQuestion" for="receipt-input">
            <!-- {{'Have you already filed a report? Enter your receipt.'|translate}} -->
            Sudah melapor? Masukkan kode:
          </label>
        </div>
        <div class="d-flex justify-content-start">
          <div class="form-group d-block mx-auto">
            <div class="input-group">
              <input class="d-none" type="password" name="disable-autocomplete" />
              <input [autocomplete]="'off'" #receiptinput="ngModel" [(ngModel)]="formattedReceipt" id="receipt-input" class="form-control text-center rounded-start" type="text" autocomplete="receipt" name="receipt" maxlength="19" size="19" customReceiptValidator>
              <button [disabled]="formattedReceipt.length < 16 || authenticationService.loginInProgress || !receiptinput.errors?.['receiptvalidator']" (click)="viewReport()" id="ReceiptButton" class="btn btn-primary" type="submit">
                <span>
                  {{'Log in'|translate}}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      @if (appDataService.errorCodes.code === 9) {
        <div class="login-error-box-homepage text-danger">
          {{'The receipt is either invalid or the report has expired.'|translate}}
        </div>
      }
    </div>
  </div>


<!-- 
<div class="container text-center mt-5" style="display:flex;justify-content: center;">
  <div class="card mx-auto w-50 nav-cst-shadow2" style="position: relative; top: -50px;z-index: 2;position: absolute;top: 400px;">
    <div class="card-body">
      <h2 class="fs-5 text-left">Sudah melapor?</h2>
      <form class="row g-2 justify-content-center mt-2">
        <div class="col-auto" style="width: 80%;">
          <input type="text" class="form-control nav-cst-shadow2" placeholder="Kode pelaporan" style="width:100%;" maxlength="12">
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary nav-cst-shadow2">Cek</button>
        </div>
      </form>
    </div>
  </div>
</div> -->