
<!-- <footer id="FooterBox">
  @if (appDataService.public.node) {
    @if (appDataService.public.node.footer) {
      <markdown id="CustomFooter" class="vcenter preformatted mb-1" [data]="appDataService.public.node.footer | stripHtml"></markdown>
    }
    @if (appDataService.public.node.footer_whistleblowing_policy || appDataService.public.node.footer_privacy_policy) {
      <div id="Policies" class="vcenter mb-1">
        @if (appDataService.public.node.footer_whistleblowing_policy) {
          <a href="{{appDataService.public.node.footer_whistleblowing_policy}}">{{'Whistleblowing Policy' | translate}}</a>
        }
        @if (appDataService.public.node.footer_whistleblowing_policy && appDataService.public.node.footer_privacy_policy) {
          <span> - </span>
        }
        @if (appDataService.public.node.footer_privacy_policy) {
          <a href="{{appDataService.public.node.footer_privacy_policy}}">{{'Privacy Policy' | translate}}</a>
        }
      </div>
    }
    <div id="AttributionClause" class="vcenter">Powered by <a href="https://www.globaleaks.org/" title="GlobaLeaks - Secure Whistleblowing Software" rel="noreferrer" target="_blank">GlobaLeaks</a></div>
  }
</footer> -->
<footer class="bg-light py-4">
  <div class="container">
    <div class="row">
        <div class="col-md-6 text-left">
            <img src="data/custom/logo.png" alt="Logo" width="30" height="30" class="mb-2">
            <h6 class="fw-bold">Fakultas MIPA Universitas Udayana</h6>
            <p class="mb-0 fs-6">Unggul, Mandiri, Berbudaya</p>
            <p id="AttributionClause">Powered by <a href="https://www.globaleaks.org/" title="GlobaLeaks - Secure Whistleblowing Software" rel="noreferrer" target="_blank">GlobaLeaks</a></p>
        </div>
    </div>
  </div>
</footer>
